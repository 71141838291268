import React, { useContext, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { PieChart } from 'react-chartkick'

import { noDataText } from '../../constants/chartsTexts'

import { DateControlContext } from 'contexts/DateControlContext'
import { loadSupportStatistics } from '../../api/dashboard'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import NoChartData from 'uiKit/NoChartData'

import * as S from './CircleChart.style'

interface Props {
  supportStatistics: any
  botId: string
}

const CircleChart: React.FC<Props> = ({ supportStatistics: chartData, botId }) => {
  const [loading, setLoading] = useState(false)
  const { startDate, endDate } = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    loadSupportStatistics(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
      .finally(() => setLoading(false))
  }, [endDate])

  const data = useMemo(() => chartData && ([
    ['By chatbot', chartData?.byChatBot],
    ['By customer support', chartData?.byCustomerSupport],
  ]), [chartData])

  const colors = ['rgb(37, 0, 64)', '#F3B116']

  const renderComponent = () => {
    if (loading) {
      return (
        <S.LoaderContainer>
          <LoaderSmall showLoader={true}/>
        </S.LoaderContainer>
      )
    } else if (chartData?.byChatBot || chartData?.byCustomerSupport) {
      return (
        <PieChart data={data} colors={colors} legend={'bottom'} />
      )
    } else {
      return (
        <NoChartData text={noDataText} />
      )
    }
  }

  return renderComponent()
}

const mapStateToProps = (state: { supportStatistics: any }) => ({
  supportStatistics: state.supportStatistics
})

export default connect(mapStateToProps)(CircleChart)
