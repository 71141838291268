import React from 'react'
import { withStyles } from '@material-ui/core'

import { styles } from './styles'
import ReactTooltip from 'react-tooltip'
import { getAttributeColor } from '../../helpers/getAttributeColor'

const AttributeItem = props => {
  const {
    classes,
    attribute,
    onClick,
    selectedAttribute,
    index,
    onMouseEnter,
  } = props

  return (
    <>
      {attribute.type === 'GROUP' ? (
        <span className={classes.category}>{attribute.title}</span>
      ) : (
        <div
          className={classes.optionWrap}
          style={{
            backgroundColor: index === selectedAttribute && 'var(--color-hover-overlay-secondary)',
          }}
          data-autotest={`attribute-name-${index}`}
          onMouseMove={() => onMouseEnter(index)}
          onClick={() => onClick(attribute)}>
          <div
            className={classes.option}
            key={attribute.id}
            data-tip
            data-for={`${attribute.name}`}
            style={{ backgroundColor: getAttributeColor(attribute.type) }}>
            {attribute.name}
          </div>

          {attribute.description && (
            <ReactTooltip
              className={classes.tooltip}
              effect="solid"
              place="left"
              type="dark"
              id={`${attribute.name}`}>
              {attribute.description}
            </ReactTooltip>
          )}
        </div>
      )}
    </>
  )
}

export default withStyles(styles)(AttributeItem)
