import styled from 'styled-components'

const Container = styled.div<{ isFromEdit: boolean }>`
  width: 100%;
  background-color: var(--color-white);
  padding: 24px;
  border-radius: ${({ isFromEdit }) => (isFromEdit ? '0' : '10px')};
  box-shadow: ${({ isFromEdit }) => (isFromEdit ? 'unset' : '4px 4px 29px rgba(19, 69, 186, 0.0855129)')};
  margin-top: ${({ isFromEdit }) => (isFromEdit ? '0' : '32px')};
  margin-bottom: ${({ isFromEdit }) => (isFromEdit ? '0' : '16px')};
  position: relative;
  padding-top: ${({ isFromEdit }) => (isFromEdit ? '8px' : '24px')};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

const Title = styled.p`
  margin: 0;
  font-size: 14px;

  font-weight: ${({ isFromEdit }) => (isFromEdit ? '400' : '700')};
  color: ${({ isFromEdit }) => (isFromEdit ? 'var(--color-text-secondary)' : 'var(--color-text-primary)')};
`

const Form = styled.div`
  display: flex;
  margin-top: 8px;
  width: 100%;
  gap: 16px;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
`

const Button = styled.button`
  background: var(--color-button-secondary);
  min-width: 80px;
  height: 48px;
  border-radius: 10px;
  border: none;
  color: var(--color-text-on-secondary);
  font-size: 16px;
  &:disabled {
    background: var(--color-text-placeholder);
  }
`

const DisableScreen = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0.3;
  background-color: var(--color-selago-grey);
`

const Textarea = styled.textarea<{ isError: boolean; isFromEdit: boolean }>`
  position: relative;
  height: ${({ isFromEdit }) => (isFromEdit ? '250px' : '176px')};
  font-size: 14px;
  padding: 40px 24px 10px 24px;
  word-break: break-word;
  border-color: ${({ isError }) => isError && 'var(--color-text-error)'};
`

const Error = styled.div`
  font-size: 12px;
  position: absolute;
  color: var(--color-text-error);
  bottom: 44px;
  left: 0;
`

const InputContainer = styled.div<{ isError: boolean; isFromEdit: boolean }>`
  width: 100%;
  margin-bottom: -60px;
  z-index: 3;

  & input {
    font-weight: 600;
    font-size: ${({ isFromEdit }) => (isFromEdit ? '14px' : '16px')};
    color: rgb(68, 68, 68);
    border-color: rgb(229, 229, 229);
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: ${({ isError }) => isError && 'var(--color-text-error)'};
  }
`

export { Container, Title, Form, DisableScreen, Button, Textarea, Error, InputContainer }
