import React, { ChangeEvent, useEffect, useState } from 'react'
import Select from 'react-select'

import { alertSuccess } from 'api'
import {
  getDefaultCustomPrompt,
  getNLPConfig,
  getAvailableGPTModels,
  saveNLPConfig,
} from 'tabs/nlp/api/websiteKnowledgeBase'

import PromptForm from '../PromptForm'

import * as S from './SettingsTab.style'
import {customStyles} from './SettingsTab.style'

type GPTModelOption = { value: string; label: string } | undefined

const getRandomObject = (array: string[]) => {
  return array[Math.floor(Math.random() * array.length)]
}

const placeholderOptions = [
  'You are a smart AI assistant who ...',
  'You are a caribbean pirate who was never caught ...',
  'You are a librarian that loves recommending books ...',
  'You are a wizard that predicts future based on your knowledge ...',
]

export const SettingsTab = ({ activeBot }): JSX.Element => {
  const [enableSaveConfig, setEnableSaveConfig] = useState(false)
  const [GPTModel, setGPTModel] = useState<GPTModelOption>()
  const [originalGPTModel, setOriginalGPTModel] = useState<GPTModelOption>()
  const [modelOptions, setModelOptions] = useState([])

  const [placeholderText, setPlaceholderText] = useState('')
  const [originalPrompt, setOriginalPrompt] = useState('')
  const [defaultPrompt, setDefaultPrompt] = useState('')
  const [promptText, setPromptText] = useState(originalPrompt)
  const [promptError, setError] = useState('')
  const [displayResetNote, setDisplayResetNote] = useState(false)
  const [enableResetDefault, setEnableResetDefault] = useState(false)

  useEffect(() => {
    Promise.all([getAvailableGPTModels(), getNLPConfig(activeBot.id)]).then(([GPTModels, nlpConfig]) => {
      const optimizedOptions = GPTModels?.map(modelName => ({ label: modelName, value: modelName }))
      const selectedOption = optimizedOptions.find(model => model.value === nlpConfig.model)
      setModelOptions(optimizedOptions)
      setGPTModel(selectedOption)
      setOriginalGPTModel(selectedOption)
      setOriginalPrompt(nlpConfig.prompt || '')
    })
  }, [])

  useEffect(() => {
    setPromptText(originalPrompt)
    regeneratePlaceholder()
    originalPrompt && getDefaultPrompt().then(result => setDefaultPrompt(result))
  }, [originalPrompt])

  const regeneratePlaceholder = () => {
    setPlaceholderText(getRandomObject(placeholderOptions))
  }

  const handleResetToDefault = () => {
    getDefaultPrompt().then(result => setPromptText(result))
    setDisplayResetNote(true)
    setError('')
    setEnableResetDefault(false)
    setEnableSaveConfig(true)
  }

  const handleSave = () => {
    const requestPayload = {
      prompt: promptText,
      model: GPTModel.value,
    }

    saveNLPConfig(activeBot.id, requestPayload).then(() =>
      alertSuccess('AI Knowledge settings are updated successfully.'),
    )
    setOriginalGPTModel(GPTModel)
    setOriginalPrompt(promptText)
    setEnableSaveConfig(false)
  }

  const getDefaultPrompt = async (): Promise<string> => {
    return await getDefaultCustomPrompt()
  }

  const handleChangeModel = (e: GPTModelOption) => {
    setGPTModel(e)
    setEnableSaveConfig(e.value !== originalGPTModel.value || promptText !== originalPrompt)
  }

  const maxPromptLength = 12000

  const handleChange = async ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
    setEnableSaveConfig(true)
    const isInvalidLength = value.length > maxPromptLength
    const isZeroLength = value.length == 0
    const containsAnyLetter = /\p{L}/u.test(value)

    if (isInvalidLength) {
      setError(`You've reached the max length for the custom prompt — ${maxPromptLength} characters`)
      setPromptText(value.substring(0, maxPromptLength))
      return
    } else {
      setError('')
    }
    setPromptText(value)
    setEnableResetDefault(value !== defaultPrompt)
    setEnableSaveConfig(value !== originalPrompt || GPTModel.value !== originalGPTModel.value)

    isZeroLength && regeneratePlaceholder()

    if (!containsAnyLetter) {
      setEnableSaveConfig(false)
    }
  }

  return (
    <div>
      <S.SelectorContainer>
        <S.SubHeader>Model</S.SubHeader>
        <Select
          styles={customStyles}
          onBlurResetsInput={false}
          onSelectResetsInput={false}
          options={modelOptions}
          simpleValue
          value={GPTModel}
          onChange={handleChangeModel}
          isLoading={!GPTModel}
          placeholder={false}
          isDisabled={!GPTModel}
        />
      </S.SelectorContainer>
      <S.SubHeader>
        Custom prompt can be used to configure chatbot's features like personality, size of response, or just about
        anything.
      </S.SubHeader>
      <PromptForm
        onResetToDefault={handleResetToDefault}
        placeholderText={placeholderText}
        promptText={promptText}
        promptError={promptError}
        enableResetDefault={enableResetDefault}
        onPromptChange={handleChange}
        displayResetNote={displayResetNote}
      />
      <S.Button onClick={handleSave} disabled={!promptText || promptError || !enableSaveConfig}>
        Save changes
      </S.Button>
    </div>
  )
}
