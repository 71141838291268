import styled from 'styled-components'

const InputHolder = styled.div`
  border: 1px solid var(--color-ghost-grey);
  width: max-content;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background-color: var(--color-white);
  margin-right: 10px;
  padding: 10px;
  max-height: 45px;
  min-width: 100%;
  position: relative;
  margin-bottom: 20px;
`

const Input = styled.input`
  padding: 10px;
  border: none;
  outline: none;
  width: 90%;
  border-radius: 5px;
  font-size: 14px;
  line-height: 19px;
  background-color: var(--color-text-on-secondary);
  color: var(--color-button-secondary);
`

const Enter = styled.div`
  width: 6%;
  cursor: pointer;
`

const Alert = styled.div`
  color: var(--color-text-error);
  font-size: 12px;
  position: absolute;
  bottom: -20px;
`

const UserSaysWrapper = styled.div`
  max-height: 175px;
  overflow: auto;
`

export { InputHolder, Input, Enter, Alert, UserSaysWrapper }
