export const styles = () => ({
  arrow: {
    marginLeft: 10,
    width: 20,
    display: 'inline-block',
  },
})

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: 210,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '& > div': {
      justifyContent: 'center',
    },
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
    },
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'var(--color-button-primary)',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: 'var(--color-button-primary)',
    display: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--color-button-primary)',
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    top: 24,
  }),
}
