import styled from 'styled-components'

export const ItemChipHelperContainer = styled.i`
  left: 208px;
  top: 0;
  position: absolute;
  display: none;
  transition: all 0.5s ease;
  background-color: var(--color-white);
  box-shadow: 0 3px 6px var(--color-issabeline-grey);
  border-radius: 24px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  
  &:hover {
    justify-content: center;
    align-items: center;
  }
`

export const ChipHelperButtonTooltip = styled.span`
  visibility: hidden;
  color: var(--color-white);
  background-color: var(--color-mine-shaft);
  padding: 8px;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  top: 44px;
  left: 50%;
  transform: translateX(-50%);
`

export const ChipHelperButtonContainer = styled.button`
  position: relative;
  border: none;
  background-color: ${props => (props.enableReaction ? 'var(--color-periwinkle)' : 'var(--color-white)')};
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-self: center;
  
  &:hover {
    background-color: ${props => (props.enableReaction ? 'var(--color-periwinkle)' : 'var(--color-hawkes-lue)')};
  }

  &:hover ${ChipHelperButtonTooltip} {
    visibility: visible;
    transition: visibility .1s .4s;
  }
`

export const CheckIconContainer = styled.span`
  position: absolute;
  top: -6px;
  right: -1px;
`


