export const styles = () => ({
  heading: {
    fontSize: 36,
    color: 'var(--color-text-primary)',
    marginTop: 64,
  },
  paragraph: {
    fontSize: 14,
    textAlign: 'center',
    color: 'var(--color-text-secondary)',
    margin: '16px auto 24px',
  },
  main: {
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  back: {
    padding: '13px 28px',
    border: 0,
    background: 'var(--color-button-primary)',
    boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
    borderRadius: 10,
    cursor: 'pointer',
    color: 'var(--color-white)',
    margin: '30px auto 0',
    display: 'block',
  },
})
